const htmlEl = document.documentElement;

const fontSizes = ["font-normal", "font-big", "font-large"];
const changeFontSize = () => {
    const currentFontSize = [...htmlEl.classList].filter(el => el.match(/font-/));
    let oldIndex = fontSizes.indexOf(currentFontSize[0]);

    if (oldIndex < fontSizes.length) {
        let newIndex = oldIndex + 1;

        if (newIndex === fontSizes.length) newIndex = 0;

        htmlEl.classList.remove(fontSizes[oldIndex]);
        htmlEl.classList.add(fontSizes[newIndex]);
        document.cookie = `fontsize=${fontSizes[newIndex]}; path=/`;
    }
}

const toggleContrast = () => {
    htmlEl.classList.toggle("a11y-contrast");

    if (htmlEl.classList.contains("a11y-contrast")) {
        document.cookie = "contrast=on; path=/";
    } else {
        document.cookie = "contrast=off; path=/";
    }
}

const accessibilityHandle = () => {
    const fontBtn = document.querySelector(".a11y-font-btn");
    const contrastBtn = document.querySelector(".a11y-contrast-btn");

    fontBtn.addEventListener("click", e => {
        e.preventDefault();
        changeFontSize();
    });

    contrastBtn.addEventListener("click", e => {
        e.preventDefault();
        toggleContrast();
    })
}

export default accessibilityHandle;