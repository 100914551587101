const closeQuestoins = items => {
    items.forEach(item => {
        item.classList.remove("open");
    });
}

const accordionHandler = () => {
    const btns = document.querySelectorAll(".group-name, .single-question");
    btns.forEach(btn => {
        btn.addEventListener("click", e => {
           e.preventDefault();

           // if (btn.classList.contains("group-name")) {
           //     closeQuestoins(btns);
           // }
           //
           // if (btn.classList.contains("single-question")) {
           //     const nestedBtns = btn.closest("ul").querySelectorAll(".single-question");
           //     closeQuestoins(nestedBtns);
           // }

           btn.classList.toggle("open");
        });
    });
}

export default accordionHandler;