import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import accordionHandler from "./accordion";
import accessibilityHandle from "./accessibility";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  accessibilityHandle();

  const tabsContainer = document.querySelectorAll(".faqs-container, .schedule-container");
  if (tabsContainer.length) accordionHandler();

  const rodoContainer = document.querySelector(".rodo-container");
  if (rodoContainer) {
    const btn = rodoContainer.querySelector(".open-btn");
    btn.addEventListener("click", e => {
      e.preventDefault();
      btn.closest("div").classList.toggle("open");
    })
  }

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};